<template>
  <div class="show-calendar-event-images-grid">
    <div class="wrapper slide-in-top ">
        <div class="wrapper-header">
            <h2>תמונות - אירוע: {{event.title}}</h2>
            <div class="exit-btn">
                <i @click="$emit('close')" class="material-icons">close</i>
            </div>
        </div>
        <label>
            סינון לפי סניף
            <el-select filterable clearable  style="display:block; width:30%;"  v-model="filter_branche" class="m-2" placeholder="סנן לפי סניף" size="large">
                <el-option
                v-for="branche in branches_options"
                :key="branche"
                :value="branche"
                />
            </el-select>
        </label>
        <span>כמות רשומות: {{filter_branches_list.length}}</span>
        <h2 v-if="event.images_amount && event.media.length > 0">כמות מדיה לפי סניפים <i @click="expand=!expand" :class="{rotate:expand}" class="material-icons expand_icon ">expand_more</i></h2>
        <div v-if="event.images_amount && event.media.length > 0" :class="{open:expand}" class="uploaded_media">
            <table>
                <tr>
                    <th>סניף</th>
                    <th>כמות</th>
                </tr>
                <template v-for="[branche,amount] in counted_branches_uploaded_media" :key="branche">
                    <tr>
                        <td>{{branche}}</td>
                        <td>{{event.images_amount}} / {{amount}}</td>
                    </tr>
                </template>
            </table>
        </div>
        <br>
        <div class="wrapper-grid">
                <template v-for="event in filter_branches_list" :key="event.id">
                    <div class="card">
                        <div class="discribe">
                            <p>הועלה ע"י {{event.user_name}}</p>
                            <p>שייך לסניף: {{event.branche}}</p>
                            <p>מס הסניף: {{event.branche_num}}</p>
                            <p>נוצר בתאריך: {{display_date_and_time_from_iso(event.date)}}</p>
                        </div>
                        <div class="image">
                            <img v-if="!event.img_loaded" :src="defualt_photo" alt="תמונה נטענת">
                            <img v-show="event.img_loaded" :src="event.image" alt="תמונה לא נטענה" @load="is_img_load(event)">
                        </div>
                    </div>  
                </template>
        </div>
    </div>
  </div>
</template>

<script>
import {display_date_and_time_from_iso} from '../../../../Methods/Dates'
import defualt_photo from '../../../../assets/images/defualt_photo.png'
import { computed, ref } from 'vue'
export default {
    props:['event'],
    emits:['close'],
    setup(props){

        const filter_branche = ref('')
        const expand = ref(false)
        const counted_branches_uploaded_media = ref(new Map())

        const is_img_load = (event) => {
            event.img_loaded = true
        }

        const count_any_branche_media_uploaded = () => {
            props.event.media.forEach(m => {
                if(counted_branches_uploaded_media.value.has(m.branche)){
                    counted_branches_uploaded_media.value.set(m.branche,counted_branches_uploaded_media.value.get(m.branche)+1)
                }else{
                    counted_branches_uploaded_media.value.set(m.branche,1)
                }
            });
        }

        const branches_options = ref(computed(() => {
            return [...counted_branches_uploaded_media.value.keys()]
        }))

        const filter_branches_list = ref(computed(() => {
            if(!filter_branche.value) return props.event.media
            return props.event.media.filter(m => m.branche == filter_branche.value)
        }))

        count_any_branche_media_uploaded()

        return{
            display_date_and_time_from_iso,
            defualt_photo,
            is_img_load,
            count_any_branche_media_uploaded,
            counted_branches_uploaded_media,
            expand,
            filter_branche,
            branches_options,
            filter_branches_list
        }
    }
}
</script>

<style scoped>
    .show-calendar-event-images-grid{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.95);
        padding: 0 5px;
        overflow-y: auto;
    }
    .show-calendar-event-images-grid > .wrapper{
        width: 100%;
        max-width: 1200px;
        height: auto;
        overflow: hidden;
        margin: 0 auto;
    }
    .wrapper-header{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .wrapper-grid{
        width: 100%;
        overflow-y: visible;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-auto-rows: 400px;
        grid-gap: 5px;
    }
    @media screen and (max-width: 1200px) {
        .wrapper-grid{
            grid-template-columns: repeat(2,1fr);
        }
    }
    @media screen and (max-width: 600px) {
        .wrapper-grid{
            grid-template-columns: repeat(1,1fr);
        }
    }
    
    .card{
        border-radius: 50px;
        background: #fff;
        color: #333;
    }
    .card:hover{
        border:5px solid lightgreen
    }
 
    .card > .discribe{
        width: 100%;
        height: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5px 0;
    }
    .card > .discribe > p{
        font-weight: 400;
    }
    .card > .image{
        width: 100%;
        height: calc(100% - 100px);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .card > .image img{
        max-width: 100%;
        max-height: 90%;
    }
   
    .slide-in-top {
	    -webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	    animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }
    @keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 18px;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: var(--purple);
        color: white;
        font-size: 20px;
    }

    .uploaded_media{
        height: 0;
        overflow-y: auto;
        transition: height ease-in 0.5s;
    }
    .uploaded_media.open{
        height: auto;
    }
    .expand_icon{
        color: #fff;
        cursor: pointer;
        user-select: none;
        transition: transform 0.5s ease;
    }
    .expand_icon.rotate{
        transform: rotate(180deg);
    }
    .exit-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        z-index: 16;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        color: #fff;
        background: var(--blue);
        border-radius: 50%;
        cursor: pointer;
    }

</style>